.slider {
    @apply w-full flex flex-row;
}

.sliderLeft {
    @apply min-w-[150px];

    ul {
        button {
            @apply relative mb-4 flex flex-row items-center w-full justify-between rounded-l-md border-y border-l border-transparent bg-gray-100 px-2 py-2 text-center text-sm font-medium text-gray-400 transition-colors duration-150 ease-out outline-none;
            height: 38px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.isSelected,
            &:focus,
            &:active,
            &:hover {
                @apply bg-white text-black border-accent-400 drop-shadow-md;
            }
        }
    }
}

.sliderRight {
    overflow: hidden;
    $n: 20;

    @for $i from 1 through $n {
        input.selector:nth-of-type(#{$i}) {
            display: none;

            &:checked~article:nth-of-type(#{$i}) {
                @apply border border-accent-400 drop-shadow-md z-20;
                visibility: visible;
                height: 350px;

                & > .ledgend {
                    top: calc(17px + (($i - 1) * 54px));
                }
            }
        }
    }
}

.article {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    @apply h-0 relative bg-white;
    visibility: hidden;
}

.ledgend {
    @apply rotate-90 bg-white;
    position: absolute;
    left: -18px;
    top: 17px;
    height: 4px;
    width: 37px;
}
