// Color
$color_primary: #D97D0D;
$color_primary-light: #e49818; 
$color_white: #fff;
$color_black: #000;
$color_dark: #1D1D20; // use in hover
$color_dark_2: #252529;
$color_disabled: #949494;
$color_light-blue: #F6FAFF;

$html-font-size: 16px;

// Layout
$layout-width: rem(1180);

// Fonts
$fw-semiBold: 600;
$fw-medium: 500;
$fw-light: 300;
$fw-extraLight: 200;

$shadow-base: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
$shadow-focus: $shadow-base;
$shadow-product-page: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);;
$text-shadow: 1px 4px 3px rgba(0, 0, 0, 0.20);