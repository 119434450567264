@import '../styles/variables.scss';

// px to rem
@function stripUnit($value) {
  @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
  @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

@mixin full-content() {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

@mixin selection() {
  border: solid 2px $color_dark;
  border-radius: rem(20);
  color: $color_dark;
  line-height: 1.2;
  font-size: rem(17);
  padding: rem(5) rem(15);
}

@mixin text-clamp($lines: 2, $line-height: false) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;

  // Fallback for non-Webkit browsers
  // (won't show `…` at the end of the block)
  @if $line-height {
    max-height: $line-height * $lines * 1px;
  }
}
 