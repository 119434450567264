@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';

.fullWidthSlider {
  height: rem(520);
  position: relative;

  @media (max-width: rem(767)) {
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: rem(1180);
  }
}

.sliderContainer {
  width: 100%;
  max-width: $layout-width;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.sliderContent {
  position: absolute;
  padding: 2rem 5rem;
  @include full-content()
}

.swiperImg {
  object-fit: cover;
  @include full-content()
}