@import '../../../styles/variables.scss';
@import '../../../styles/mixin.scss';

.headerContent {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
  }
}

.storeBu {
  @media (min-width: 1440px) {
    margin-right: rem(-100);
  }

  @media (max-width: 767px) {
    order: 2;
    width: 100%;
    display: flex;
    margin-top: rem(10);
    gap: rem(15)
  }

  span {
    font-size: rem(17);

    @media (max-width: 767px) {
      display: block;
      margin-bottom: rem(5);
    }
  }
}

.iconSearch {
  margin-right: rem(30);

  img {
    height: rem(21);
    position: relative;
    top: rem(3);
  }
}

.selection {
  padding: rem(15) 0 0;

  @media (min-width: 768px) {
    @include selection();
  }

  @media (max-width: 767px) {
    flex: 1;
    max-width: rem(250);
  }

  select {
    width: auto;
    padding: 0;
    font-size: rem(17);
    color: $color_dark;
    width: rem(100);
    padding-right: rem(20);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @media (min-width: 768px) {
    background-position: right rem(-5) center;
    margin-left: rem(5);

    }
  
    @media (max-width: 767px) {
      @include selection();
      width: 100%;
      font-size: rem(14);
      padding-right: rem(30);
    }
  }
}

.logo {
  width: rem(100);

  @media (min-width: 768px) {
    width: rem(170);
  }
}

.cartIcon {
  width: rem(33);
  height: rem(27);

  @media (min-width: 768px) {
    height: rem(33);
  }
}

.cartItemQty {
  right: rem(-5);
  bottom: rem(-5);
}

.searchWrapper {
  @media (max-width: 767px) {
    // width: 2rem;
  }
}

.searchForm {
  border-bottom: 2px solid $color_primary;

  @media (max-width: 1023px) {
    border-radius: rem(6);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid #f1f1f1;
    border-bottom: 2px solid #F19321;
    margin-top: rem(25);
    padding: rem(5) 0 0;
    z-index: 2;

    button {
      position: absolute;
      right: rem(5);
      top: 0;
      bottom: 0;
      margin: auto;
      width: rem(30);

      img {
        height: rem(21);
      }
    }
  }
}

.searchInput {
  border: 0;
  border-radius: 0;
  padding-right: rem(45);

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.searchSubmitButton {
  background-color: $color_white;
  img {
    height: rem(18);
  }
}

.customerAccount {
  > span {
    line-height: rem(24);
  }
}

.accountMobile {
  margin-right: rem(30);

  > div {
    margin-top: rem(35);
  }
}

// Navigation
.bicNavigation { 
  @media (min-width: 768px) {
    position: relative;
    left: -50vw;
    margin-left: 50%;
    top: 100%;
    width: 100vw;
    // box-shadow: 0px 2px 3px 0px rgba(41, 41, 41, 0.08);
    box-shadow: 0 3px 7px -3px #9494a1;
  }
}

.bicNavigationWrapper {
  padding: rem(16);

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    max-width: 1180px;
    margin: 0 auto;
  }
}

.navItem {
  color: $color_dark_2;
  font-size: rem(16);

  @media (min-width: 768px) {
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    font-size: rem(15);
    line-height: rem(18);
    padding: 0 rem(16);
    max-width: rem(136);
    margin-right: rem(30);
    height: rem(55);
    display: flex;
    align-items: center;
    justify-self: center;
    font-weight: bold;
    transition: all .3s;

    > span {
      width: 100%;
    }

    &:hover {
      border-bottom: 4px solid $color_primary;
    }
  }
}

// Menu Mobile
@media (max-width: 767px) {
  .bicNavigation {
    height: 100vh;
    width: calc(100% + rem(52));
    margin-left: rem(-26);
    left: calc(-100% - 52px);
    transition: .3s all;
    overflow: auto;
    display: none;
  }

  .arrowBtn {
    background: $color_light-blue;
    display: flex;
    font-size: rem(16);
    padding: rem(25) rem(16);

    img {
      transform: rotate(90deg);
      margin-right: rem(10);
    }
  }

  .navItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    padding: rem(10) 0;
    position: relative;
    z-index: 1;
  }

  .subNav {
    background: white;
    position: absolute;
    width: 100%;
    top: 0;
    left: -100%;
    z-index: 2;
    transition: .3s all;
  }

  .subNavOpen {
    left: 0;
  }
}

